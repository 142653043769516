<template>
  <!-- Page Content  -->
  <b-container fluid>
    <b-row>
      <!-- BEGIN:Warning -->
      <b-col md="12">
        <iq-card
          bodyClass="relative-background"
          class="iq-card-block iq-card-stretch iq-card-height"
        >
          <template v-slot:body>
            <div class="align-items-center">
              <div class="text-center">
                <div class="rounded-circle iq-card-icon iq-bg-primary mr-3">
                  <i class="ri-user-2-fill"></i>
                </div>
                <h4>PENGUMUMAN</h4>
                <h5>WAJIB DIBACA</h5>
                <br />
                <h6>
                  Setiap pengisian nominal dalam kontrak kerjasama diwajibkan
                  ditulis dalam format ribuan (X 1000)
                </h6>
                <h5>Contoh : 1.250.000 menjadi 1.250</h5>
              </div>
            </div>
            <div class="background-image">
              <img src="@/assets/images/page-img/36.png" class="img-fluid" />
            </div>
          </template>
        </iq-card>
      </b-col>
      <!-- ./END:Warning -->
      <!-- BEGIN:3 Cards Header  -->
      <b-col md="6" lg="4">
        <iq-card
          class="
            iq-card
            iq-card-block
            iq-card-stretch
            iq-card-height
            iq-border-box
            iq-border-box-1
            text-primary
          "
        >
          <template v-slot:body>
            <div class="d-flex justify-content-between">
              <div class="rounded-circle iq-card-icon iq-bg-primary mr-3">
                <i class="fas fa-handshake"></i>
              </div>
              <div class="text-right">
                <h5>Memorandum of Understanding</h5>
                <h3>
                  {{
                    listData && listData.jumlahMou ? listData.jumlahMou : '0'
                  }}
                </h3>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col md="6" lg="4">
        <iq-card
          class="
            iq-card
            iq-card-block
            iq-card-stretch
            iq-card-height
            iq-border-box
            iq-border-box-1
            text-primary
          "
        >
          <template v-slot:body>
            <div class="d-flex justify-content-between">
              <div class="rounded-circle iq-card-icon iq-bg-primary mr-3">
                <i class="fas fa-file-signature"></i>
              </div>
              <div class="text-right">
                <h5>Memorandum of Agreement</h5>
                <h3>
                  {{
                    listData && listData.jumlahMoa ? listData.jumlahMoa : '0'
                  }}
                </h3>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col md="6" lg="4">
        <iq-card
          class="
            iq-card
            iq-card-block
            iq-card-stretch
            iq-card-height
            iq-border-box
            iq-border-box-1
            text-primary
          "
        >
          <template v-slot:body>
            <div class="d-flex justify-content-between">
              <div class="rounded-circle iq-card-icon iq-bg-primary mr-3">
                <i class="fas fa-bullseye"></i>
              </div>
              <div class="text-right">
                <h5>Implementation Arrangement</h5>
                <h3>
                  {{ listData && listData.jumlahIa ? listData.jumlahIa : '0' }}
                </h3>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
      <!-- ./END:3 Cards Header  -->
      <!-- BEGIN:Profile -->
      <b-col md="4">
        <iq-card
          bodyClass="relative-background"
          class="iq-card-block iq-card-stretch iq-card-height"
        >
          <template v-slot:body>
            <div class="text-center">
              <img
                class="img-fluid iq-image-full mb-4"
                src="@/assets/images/favicon-hd.png"
                alt="profile-img"
              />
              <div>
                <h4>UT</h4>
                <br />
                <h6 class="text-left">
                  <i class="fas fa-address-card"></i> Perguruan Tinggi<br />
                  <i class="fas fa-university"></i> Universitas Terbuka<br />
                  <i class="fas fa-map-marker"></i> Rumah Profile UT
                </h6>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
      <!-- ./END:Profile -->
      <!-- BEGIN:Chart 1 -->
      <!-- <b-col lg="8">
        <iq-card
          class="iq-card-block iq-card-stretch"
          bodyClass="rounded pb-primary"
        >
          <template v-slot:headerTitle>
            <h4 class="card-title">Monthly sales trend</h4>
          </template>
          <template v-slot:body>
            <div class="d-flex justify-content-around">
              <div class="price-week-box mr-5">
                <span>This Week</span>
                <h3>
                  $<Counter :value="24598" />
                  <i class="ri-funds-line text-success font-size-18"></i>
                </h3>
              </div>
              <div class="price-week-box">
                <span>Last Week</span>
                <h3>
                  $<Counter :value="74568" />
                  <i class="ri-funds-line text-danger font-size-18"></i>
                </h3>
              </div>
            </div>
            <ApexChart element="menu-chart-02" :chartOption="chart1" />
          </template>
        </iq-card>
      </b-col> -->
      <!-- ./END:Chart 1 -->
      <!-- BEGIN:Chart 2 -->
      <b-col lg="8">
        <iq-card
          class-name="iq-card-block iq-card-stretch iq-card-height overflow-hidden"
        >
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ chart2.title }}</h4>
          </template>
          <template v-slot:body>
            <ApexChart
              element="home-chart-02"
              :chartOption="chart2.bodyData"
              style="min-height: 300px"
            />
          </template>
        </iq-card>
      </b-col>
      <!-- ./END:Chart 2 -->
      <!-- BEGIN:Chart 3 -->
      <!-- <b-col md="6" lg="5">
        <iq-card
          class-name="iq-card-block iq-card-stretch iq-card-height overflow-hidden"
        >
          <template v-slot:headerTitle>
            <h4 class="card-title">Country Stats</h4>
          </template>
          <template v-slot:body>
            <AmChart
              :element="chart3.type"
              :type="chart3.type"
              :option="chart3.bodyData"
              :height="300"
            />
          </template>
        </iq-card>
      </b-col> -->
      <!-- ./END:Chart 3 -->
      <!-- BEGIN:Table Masa Berlaku PKS 3 Bulan -->
      <b-col md="12">
        <iq-card
          bodyClass="relative-background"
          class="iq-card-block iq-card-stretch iq-card-height"
        >
          <template v-slot:body>
            <div class="text-center">
              <div class="rounded-circle iq-card-icon iq-bg-primary mr-3">
                <i class="ri-file-warning-fill"></i>
              </div>
              <h4>DATA MASA BERLAKU PKS 3 BULAN</h4>
            </div>
            <div class="align-items-center">
              <b-row>
                <b-col>
                  <b-form-input
                    class="w-25"
                    id="filter-input"
                    v-model="filterMasaBerlakuPks"
                    type="search"
                    placeholder="Cari Data"
                  ></b-form-input>
                </b-col>
              </b-row>
              <b-table
                responsive
                bordered
                :fields="theadMasaBerlakuPks"
                :items="listDataMasaBerlakuPks"
                class="mt-2"
                :busy="isLoadingMasaBerlakuPks"
                :filter="filterMasaBerlakuPks"
                :per-page="perPageMasaBerlakuPks"
                :current-page="currentPageMasaBerlakuPks"
                @filtered="onFilteredMasaBerlakuPks"
                show-empty
              >
                <template v-slot:cell(sisaBerakhir)="listDataMasaBerlakuPks">
                  <div class="font-weight-bold text-danger">
                    {{ listDataMasaBerlakuPks.item.sisaBerakhir }}
                  </div>
                </template>
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
                <template #empty>
                  <div
                    class="
                      bg-light
                      text-danger
                      font-weight-bold
                      mx-auto
                      text-center
                    "
                  >
                    Data tidak ditemukan
                  </div>
                </template>
              </b-table>
              <b-row>
                <b-col>
                  <b-form-group
                    label="Per page:"
                    label-for="per-page-select"
                    label-size="sm"
                    class="d-flex"
                  >
                    <b-form-select
                      class="ml-2"
                      id="per-page-select"
                      v-model="perPageMasaBerlakuPks"
                      :options="pageOptionsMasaBerlakuPks"
                      size="sm"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-pagination
                    class="float-right"
                    v-model="currentPageMasaBerlakuPks"
                    :total-rows="totalRowsMasaBerlakuPks"
                    :per-page="perPageMasaBerlakuPks"
                  ></b-pagination>
                </b-col>
              </b-row>
            </div>
          </template>
        </iq-card>
      </b-col>
      <!-- ./END:Table Masa Berlaku PKS 3 Bulan -->
      <!-- BEGIN:Table Dokumen Sudah Upload -->
      <b-col md="12">
        <iq-card
          bodyClass="relative-background"
          class="iq-card-block iq-card-stretch iq-card-height"
        >
          <template v-slot:body>
            <div class="text-center">
              <div class="rounded-circle iq-card-icon iq-bg-primary mr-3">
                <i class="ri-file-upload-fill"></i>
              </div>
              <h4>DATA PKS YANG SUDAH UPLOAD DOKUMEN</h4>
            </div>
            <div class="align-items-center">
              <b-row>
                <b-col>
                  <b-form-input
                    class="w-25"
                    id="filter-input"
                    v-model="filterSudahUpload"
                    type="search"
                    placeholder="Cari Data"
                  ></b-form-input>
                </b-col>
              </b-row>
              <b-table
                responsive
                bordered
                :fields="theadSudahUpload"
                :items="listDataSudahUpload"
                class="mt-2"
                :busy="isLoadingSudahUpload"
                :filter="filterSudahUpload"
                :per-page="perPageSudahUpload"
                :current-page="currentPageSudahUpload"
                @filtered="onFilteredSudahUpload"
                show-empty
              >
                <template v-slot:cell(statusValidasi)="listDataSudahUpload">
                  <div>
                    <router-link
                      v-if="listDataSudahUpload.item.idStatusValidasi == 0"
                      class="btn bg-primary rounded-sm"
                      v-b-tooltip="'Validasi'"
                      size="sm"
                      role="button"
                      :to="{
                        name:
                          listDataSudahUpload.item.idJenisPks == 6
                            ? 'kerjasama.mou.detail'
                            : listDataSudahUpload.item.idJenisPks == 12
                            ? 'kerjasama.kontrak.detail'
                            : 'kerjasama.pks.detail',
                        params: {
                          idPks: listDataSudahUpload.item.idPks,
                          statusValidasi:
                            listDataSudahUpload.item.idStatusValidasi
                        }
                      }"
                      ><i class="ri-check-line m-0"></i>Validasi</router-link
                    >
                    <div v-else class="font-weight-bold text-success" disabled>
                      <i class="ri-check-double-line m-0 mr-1"></i>Sudah
                      Divalidasi
                    </div>
                  </div>
                  <!-- <div
                    class="text-success font-weight-bold"
                    v-if="listDataSudahUpload.item.statusValidasi == 1"
                  >
                    Sudah divalidasi
                  </div>
                  <div v-else>
                    <b-button
                      class="mx-1 btn-block font-weight-bold"
                      variant=" bg-primary"
                      @click="validasiDokumen"
                      ><i class="ri-check-line m-0"></i>Validasi</b-button
                    >
                  </div> -->
                </template>
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
                <template #empty>
                  <div
                    class="
                      bg-light
                      text-danger
                      font-weight-bold
                      mx-auto
                      text-center
                    "
                  >
                    Data tidak ditemukan
                  </div>
                </template>
              </b-table>
              <b-row>
                <b-col>
                  <b-form-group
                    label="Per page:"
                    label-for="per-page-select"
                    label-size="sm"
                    class="d-flex"
                  >
                    <b-form-select
                      class="ml-2"
                      id="per-page-select"
                      v-model="perPageSudahUpload"
                      :options="pageOptionsSudahUpload"
                      size="sm"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-pagination
                    class="float-right"
                    v-model="currentPageSudahUpload"
                    :total-rows="totalRowsSudahUpload"
                    :per-page="perPageSudahUpload"
                  ></b-pagination>
                </b-col>
              </b-row>
            </div>
          </template>
        </iq-card>
      </b-col>
      <!-- ./END:Table Dokumen Sudah Upload -->
      <!-- BEGIN:Table PKS Per Unit -->
      <b-col md="12">
        <iq-card
          bodyClass="relative-background"
          class="iq-card-block iq-card-stretch iq-card-height"
        >
          <template v-slot:body>
            <div class="text-center">
              <div class="rounded-circle iq-card-icon iq-bg-primary mr-3">
                <i class="ri-file-2-fill"></i>
              </div>
              <h4>DATA PKS PER UNIT</h4>
            </div>
            <div class="align-items-center">
              <b-row>
                <b-col>
                  <b-form-input
                    class="w-25"
                    id="filter-input"
                    v-model="filterPksPerUnit"
                    type="search"
                    placeholder="Cari Data"
                  ></b-form-input>
                </b-col>
              </b-row>
              <b-table
                responsive
                bordered
                :fields="theadPksPerUnit"
                :items="listDataPksPerUnit"
                class="mt-2"
                :busy="isLoadingPksPerUnit"
                :filter="filterPksPerUnit"
                :per-page="perPagePksPerUnit"
                :current-page="currentPagePksPerUnit"
                @filtered="onFilteredPksPerUnit"
                show-empty
              >
                <template v-slot:cell(upbjj)="listDataPksPerUnit">
                  <div class="text-left">
                    {{ listDataPksPerUnit.item.kodeUpbjj }} |
                    {{ listDataPksPerUnit.item.namaUpbjj }}
                  </div>
                </template>
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
                <template #empty>
                  <div
                    class="
                      bg-light
                      text-danger
                      font-weight-bold
                      mx-auto
                      text-center
                    "
                  >
                    Data tidak ditemukan
                  </div>
                </template>
              </b-table>
              <b-row>
                <b-col>
                  <b-form-group
                    label="Per page:"
                    label-for="per-page-select"
                    label-size="sm"
                    class="d-flex"
                  >
                    <b-form-select
                      class="ml-2"
                      id="per-page-select"
                      v-model="perPagePksPerUnit"
                      :options="pageOptionsPksPerUnit"
                      size="sm"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-pagination
                    class="float-right"
                    v-model="currentPagePksPerUnit"
                    :total-rows="totalRowsPksPerUnit"
                    :per-page="perPagePksPerUnit"
                  ></b-pagination>
                </b-col>
              </b-row>
            </div>
          </template>
        </iq-card>
      </b-col>
      <!-- ./END:Table PKS Per Unit -->
      <!-- BEGIN:Table PKS -->
      <b-col md="12">
        <iq-card
          bodyClass="relative-background"
          class="iq-card-block iq-card-stretch iq-card-height"
        >
          <template v-slot:body>
            <div class="text-center">
              <div class="rounded-circle iq-card-icon iq-bg-primary mr-3">
                <i class="ri-currency-fill"></i>
              </div>
              <h4>DATA PKS YANG SUDAH DIBAYAR</h4>
            </div>
            <div class="align-items-center">
              <b-row>
                <b-col>
                  <b-form-input
                    class="w-25"
                    id="filter-input"
                    v-model="filterPks"
                    type="search"
                    placeholder="Cari Data"
                  ></b-form-input>
                </b-col>
              </b-row>
              <b-table
                responsive
                bordered
                :fields="theadPks"
                :items="listDataPks"
                class="mt-2"
                :busy="isLoadingPks"
                :filter="filterPks"
                :per-page="perPagePks"
                :current-page="currentPagePks"
                @filtered="onFilteredPks"
                show-empty
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
                <template #empty>
                  <div
                    class="
                      bg-light
                      text-danger
                      font-weight-bold
                      mx-auto
                      text-center
                    "
                  >
                    Data tidak ditemukan
                  </div>
                </template>
              </b-table>
              <b-row>
                <b-col>
                  <b-form-group
                    label="Per page:"
                    label-for="per-page-select"
                    label-size="sm"
                    class="d-flex"
                  >
                    <b-form-select
                      class="ml-2"
                      id="per-page-select"
                      v-model="perPagePks"
                      :options="pageOptionsPks"
                      size="sm"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-pagination
                    class="float-right"
                    v-model="currentPagePks"
                    :total-rows="totalRowsPks"
                    :per-page="perPagePks"
                  ></b-pagination>
                </b-col>
              </b-row>
            </div>
          </template>
        </iq-card>
      </b-col>
      <!-- ./END:Table PKS -->
    </b-row>
  </b-container>
</template>
<script>
import * as am4core from '@amcharts/amcharts4/core'
// import AmChart from '@/components/core/charts/AmChart'
import ApexChart from '@/components/core/charts/ApexChart'
// import Counter from '@/components/core/counter/Counter'
// import { core } from '@/config/pluginInit'
import axios from 'axios'
import { mapState } from 'vuex'
import moment from 'moment'
import 'moment/locale/id'
// import 'moment/dist/locale/id'
// moment.locale('id')

export default {
  name: 'Dashboard6',
  computed: {
    ...mapState('authService', ['token'])
  },
  async mounted() {
    // core.index()
    /** Set the initial number of items */
    this.totalRowsPks = this.listDataPks.length
    /** data */
    await Promise.all([
      this.getDataGrafik_(),
      this.getListSudahUpload_(),
      this.getListPksPerUnit_(),
      this.getListMasaBerlakuPks_()
    ])
  },
  beforeDestroy() {
    /** Dispose amchart before leave page (mandatory) */
    am4core.disposeAllCharts()
  },
  components: { ApexChart },
  methods: {
    /**
     * Trigger pagination to update the number of buttons/pages due to filtering
     */
    onFilteredMasaBerlakuPks(filteredItems) {
      this.totalRowsMasaBerlakuPks = filteredItems.length
      this.currentPageMasaBerlakuPks = 1
    },
    onFilteredSudahUpload(filteredItems) {
      this.totalRowsSudahUpload = filteredItems.length
      this.currentPageSudahUpload = 1
    },
    onFilteredPksPerUnit(filteredItems) {
      this.totalRowsPksPerUnit = filteredItems.length
      this.currentPagePksPerUnit = 1
    },
    onFilteredPks(filteredItems) {
      this.totalRowsPks = filteredItems.length
      this.currentPagePks = 1
    },
    /**
     * Get data grafik
     */
    async getDataGrafik_() {
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/grafikDashboard`,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.listData = res.data
        })
        .catch(err => {
          console.log('ERROR grafikDashboard:', err)
          throw new Error(err)
        })
      return result
    },
    /**
     * Get data list masa berlaku pks
     */
    async getListMasaBerlakuPks_() {
      this.isLoadingMasaBerlakuPks = true
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/dashboard/berlakuDashboard`,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.listDataMasaBerlakuPks = res.data
          this.totalRowsMasaBerlakuPks = this.listDataMasaBerlakuPks.length
          /** add waktu sisa berakhir */
          this.listDataMasaBerlakuPks = this.listDataMasaBerlakuPks.map(
            data => ({
              ...data,
              sisaBerakhir: moment(data.tanggalBerakhir, 'YYYY-MM-DD').fromNow()
            })
          )
        })
        .catch(err => {
          console.log('ERROR getListMasaBerlakuPks:', err)
          throw new Error(err)
        })
      this.isLoadingMasaBerlakuPks = false
      return result
    },
    /**
     * Get data list sudah upload
     */
    async getListSudahUpload_() {
      this.isLoadingSudahUpload = true
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/dashboard/validasiPks`,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.listDataSudahUpload = res.data
          this.totalRowsSudahUpload = this.listDataSudahUpload.length
        })
        .catch(err => {
          console.log('ERROR getListSudahUpload:', err)
          throw new Error(err)
        })
      this.isLoadingSudahUpload = false
      return result
    },
    /**
     * Get data list pks per unit
     */
    async getListPksPerUnit_() {
      this.isLoadingPksPerUnit = true
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/getListPksPerUnit`,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.listDataPksPerUnit = res.data
          this.totalRowsPksPerUnit = this.listDataPksPerUnit.length
        })
        .catch(err => {
          console.log('ERROR getListPksPerUnit:', err)
          throw new Error(err)
        })
      this.isLoadingPksPerUnit = false
      return result
    },
    /**
     * Validasi dokumen
     */
    validasiDokumen() {
      this.$toast.info('Sedang dalam pengembangan')
    }
  },
  data() {
    return {
      // Data list
      listData: [],
      listDataPksPerUnit: [],
      listDataMasaBerlakuPks: [],
      listDataSudahUpload: [],
      // Tabel Options Masa Berlaku PKS
      isLoadingMasaBerlakuPks: false,
      filterMasaBerlakuPks: null,
      perPageMasaBerlakuPks: 5,
      pageOptionsMasaBerlakuPks: [5, 10, 15, { value: 100, text: '100' }],
      currentPageMasaBerlakuPks: 1,
      totalRowsMasaBerlakuPks: 1,
      // Tabel Options Sudah Upload
      isLoadingSudahUpload: false,
      filterSudahUpload: null,
      perPageSudahUpload: 5,
      pageOptionsSudahUpload: [5, 10, 15, { value: 100, text: '100' }],
      currentPageSudahUpload: 1,
      totalRowsSudahUpload: 1,
      // Tabel Options PKS Per Unit
      isLoadingPksPerUnit: false,
      filterPksPerUnit: null,
      perPagePksPerUnit: 5,
      pageOptionsPksPerUnit: [5, 10, 15, { value: 100, text: '100' }],
      currentPagePksPerUnit: 1,
      totalRowsPksPerUnit: 1,
      // Tabel Options PKS
      isLoadingPks: false,
      filterPks: null,
      perPagePks: 5,
      pageOptionsPks: [5, 10, 15, { value: 100, text: '100' }],
      currentPagePks: 1,
      totalRowsPks: 1,
      // Table Masa Berlaku PKS
      theadMasaBerlakuPks: [
        { label: 'Kode PKS', key: 'kodePks', class: 'text-center' },
        { label: 'Jenis PKS', key: 'jenisKs', class: 'text-center' },
        { label: 'Nama Mitra', key: 'namaMitra', class: 'text-justify w-50' },
        {
          label: 'Tanggal Berakhir',
          key: 'tanggalBerakhir',
          class: 'text-center'
        },
        {
          label: 'Waktu Sisa Berakhir',
          key: 'sisaBerakhir',
          class: 'text-center'
        }
      ],
      // Table Sudah Upload
      theadSudahUpload: [
        { label: 'Kode PKS', key: 'kodePks', class: 'text-center' },
        { label: 'Jenis PKS', key: 'kodeJenisPks', class: 'text-center' },
        { label: 'Nama PKS', key: 'namaJenisPks', class: 'text-center' },
        { label: 'Kode Mitra', key: 'kodeMitra', class: 'text-center' },
        { label: 'Nama Mitra', key: 'namaMitra', class: 'text-justify w-50' },
        { label: 'Status', key: 'statusValidasi', class: 'text-center' }
      ],
      // Table PKS Per Unit
      theadPksPerUnit: [
        { label: 'UPBJJ', key: 'upbjj', class: 'text-center w-25' },
        { label: 'Beasiswa', key: 'beasiswa', class: 'text-center' },
        { label: 'Kontrak', key: 'kontrak', class: 'text-center' },
        { label: 'MoU', key: 'mou', class: 'text-center' },
        {
          label: 'Layanan Operasional',
          key: 'layananOperasional',
          class: 'text-center'
        },
        {
          label: 'Pemanfaatan Asset',
          key: 'pemanfaatanAsset',
          class: 'text-center'
        },
        {
          label: 'Pengembangan Sumber Daya',
          key: 'pengembanganSumberDaya',
          class: 'text-center'
        },
        { label: 'Lainnya', key: 'lainnya', class: 'text-center' }
      ],
      // Table PKS
      theadPks: [
        { label: 'Kode KS', key: 'kode_ks', class: 'text-center' },
        { label: 'Kode Jenis KS', key: 'kode_jenis_ks', class: 'text-center' },
        { label: 'Keterangan', key: 'keterangan', class: 'text-left' },
        { label: 'Nomor KS UT', key: 'nomor_ks_ut', class: 'text-center' },
        {
          label: 'Nomor KS Mitra',
          key: 'nomor_ks_mitra',
          class: 'text-center'
        },
        {
          label: 'Tanggal Berakhir',
          key: 'tanggal_berakhir',
          class: 'text-center'
        }
      ],
      listDataPks: [
        {
          id: 1,
          kode_ks: '000228',
          kode_jenis_ks: '006',
          keterangan:
            'Nota kesepahaman antara Pimpinan Pusat Persatuan Guru Nahdlatul Ulama dengan Universitas Terbuka\x0D\x0A',
          nomor_ks_ut: '5897/UN31/DN/2017',
          nomor_ks_mitra: '003/PP.MoU/08/2017',
          tanggal_berakhir: '01/08/2022'
        }
      ],
      // Chart 1
      chart1: {
        series: [
          {
            name: 'This Week',
            data: [31, 40, 28, 51, 42, 109, 100]
          },
          {
            name: 'Last Week',
            data: [11, 32, 45, 32, 34, 52, 41]
          }
        ],
        chart: {
          height: 335,
          type: 'area'
        },
        colors: ['#827af3', '#00ca00'],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          type: 'datetime',
          categories: [
            '2018-09-19T00:00:00.000Z',
            '2018-09-19T01:30:00.000Z',
            '2018-09-19T02:30:00.000Z',
            '2018-09-19T03:30:00.000Z',
            '2018-09-19T04:30:00.000Z',
            '2018-09-19T05:30:00.000Z',
            '2018-09-19T06:30:00.000Z'
          ]
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm'
          }
        }
      },
      // Chart 2
      chart2: {
        title: 'Jumlah Kerjasama Per Program Studi',
        type: 'bar',
        bodyData: {
          series: [
            {
              name: 'Ongoing',
              data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
            },
            {
              name: 'Expired',
              data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
            }
          ],
          chart: {
            type: 'bar',
            height: 300,
            toolbar: {
              show: false
            }
          },

          colors: ['#827af3', '#6ce6f4'],
          plotOptions: {
            bar: {
              horizontal: !1,
              columnWidth: '55%',
              borderRadius: 4
            }
          },
          dataLabels: {
            enabled: !1
          },
          stroke: {
            show: !0,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            categories: [
              'Feb',
              'Mar',
              'Apr',
              'May',
              'Jun',
              'Jul',
              'Aug',
              'Sep',
              'Oct'
            ]
          },
          yaxis: {},
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function(e) {
                return e
              }
            }
          }
        }
      },
      // Chart 3
      chart3: {
        type: 'pie',
        bodyData: {
          colors: [
            '#0084ff',
            '#00ca00',
            '#e64141',
            '#ffd400',
            '#00d0ff',
            '#374948'
          ],
          value: ['litres'],
          category: ['country'],
          data: [
            {
              country: 'Lithuania',
              litres: 501.9,
              fill: 'red'
            },
            {
              country: 'Germany',
              litres: 165.8
            },
            {
              country: 'Australia',
              litres: 139.9
            },
            {
              country: 'Austria',
              litres: 128.3
            },
            {
              country: 'UK',
              litres: 99
            },
            {
              country: 'Belgium',
              litres: 60
            }
          ]
        }
      }
    }
  }
}
</script>
